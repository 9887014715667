<template>
    <div class="checkinAdd">
        <van-search v-model="keyword" placeholder="请输入学员名称" @input="handleSearch" />
        <div class="checkinAdd-student">
            <div @click="toAdd(item)" class="checkinAdd-item" v-for="(item,index) in list" :key="index">
                <img :src="item.student_avatar">
                <div>{{item.student_name}} ({{item.student_cellphone}})</div>
            </div>
            <div style="text-align: center" v-if="list.length == 0 && keyword !='' && !searchLoading">暂无学员~</div>
            <loadings :loading='searchLoading' />
        </div>
        <div class="checkinAdd-user">
            <div class="checkinAdd-user-item" v-for="(item,index) in addList" :key="index">
                <div class="checkinAdd-user-img"><img :src="item.student_avatar"></div>
                <div class="checkinAdd-user-name">{{item.student_name}}</div>
                <div @click.stop="todel(index)" class="checkinAdd-user-close"><van-icon size="24" color="#00DE93" name="close" /></div>
            </div>
        </div>
        <div class="checkinAdd-btn">
            <div @click="addStudent(0)">
                <van-button style="width:100%;border-radius:0" type="primary">临时插班</van-button>
            </div>
            <div @click="addStudent(1)">
                <van-button style="width:100%;border-radius:0" type="info">加入班级</van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Search } from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:'checkin-add',
        data() {
            return {
                keyword: '',
                list:[],
                addList:[],
                student_id:[],
                searchLoading:false,
            }
        },
        components: {
            [Search.name]:Search,
            loadings:Loadings,
        },
        methods: {
            getList(val){
                this.searchLoading = true
                this.$api.employee_search_students({q:val})
                .then(res=>{
                    this.list = res.data
                    this.searchLoading = false
                })
            },
            handleSearch(e){
                this.getList(e)
            },
            todel(index){
                console.log(index)
                this.addList.splice(index,1)
                this.student_id.splice(index,1)
            },
            toAdd(item){
                if(this.student_id.indexOf(item.student_id) == -1){
                    this.student_id.push(item.student_id)
                    this.addList.push(item)
                }else{
                    this.$toast('该学员已添加~')
                }
            },
            addStudent(in_class){
                if(this.student_id.length === 0){
                    this.$toast('请选择学员~')
                    return false
                }
                this.$toast.loading({
                    message: '提交中...',
                    forbidClick: true
                })
                this.$api.employee_schedule_add_member({student_id:this.student_id,course_class_id:this.$route.query.course_class_id,in_class:in_class})
                .then(res=>{
                    this.$toast('操作成功~')
                    this.$toast.clear()
                    this.$router.go(-1)
                })
                .catch(err=>{
                    console.log(err)
                    this.$toast.clear()
                    this.$toast('操作失败~')
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .checkinAdd{
        min-height: 100vh;
        background: #fff;
        &-student{
            height: 300px;
            padding: 0 12px;
            overflow-y: auto;
            position: relative;
        }
        &-item{
            display: flex;
            align-items: center;
            padding: 5px;
            background: #f8f8f8;
            margin: 5px 0;
            border-radius: 2px;
            img{
                width: 32px;
                border-radius: 50%;
                margin-right: 15px;
            }
        }
        &-user{
            padding: 0 12px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-bottom: 50px;
            &-item{
                width: 80px;
                background: #f8f8f8;
                position: relative;
                margin:3px;
                img{
                    width: 80px;
                    height: 80px;
                }
            }
            &-img{
                width: 80px;
                height: 80px;
            }
            &-name{
                width: 80px;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding: 5px 0;
            }
            &-close{
                position: absolute;
                top: 0px;
                right: 0px;
            }
        }
        &-btn{
            display: flex;
            position: fixed;
            bottom: 0;
            width: 100%;
            div{
                flex: 1;
                text-align: center;
            }
        }
    }
</style>
<style lang="less">
    .checkinAdd-student{
        .van-overlay{
            position: absolute;
        }
        .van-loading{
            position: absolute;
        }
    }
</style>